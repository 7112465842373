<!--
   Created by Terence on 2023/12/7 - 14:33
-->
<template>
  <download-banner @onInvokeApp="onInvokeApp" />
  <hover-guide-btn
    @onInvokeApp="onInvokeApp"
    :extStyle="{ bottom: '26.666666666666668rem' }"
  />
  <div class="post">
    <div class="post-header">
      <van-image
        fit="cover"
        class="post-header-avatar"
        :src="postData?.creator?.avatarUrl + '!mfit_w480_h480_jpg'"
        alt=""
      />
      <div class="post-header-user">
        <div class="post-header-user-name">
          {{ postData?.creator?.displayName }}
        </div>
        <div class="post-header-user-time">
          <!--          TODO timeFormate  -->
          {{ postData?.createdAt }}&nbsp;发布
          <span v-if="postData?.standardData?.island?.sections?.[0]?.name"
            >在<span
              class="post-header-user-time-section"
              @click="
                handleToSection(postData?.standardData?.island?.sections?.[0])
              "
            >
              {{ postData?.standardData?.island?.sections?.[0]?.name
              }}<ArrowRight :style="{ marginLeft: '0.5333333333333333rem' }" />
            </span>
          </span>
          <span v-else>该帖子</span>
        </div>
      </div>
    </div>
    <div class="post-content">
      {{ postData.content }}
    </div>
    <div class="post-imgs" v-if="postData.attachments?.length">
      <grid-imgs :imgs="postData.attachments" />
    </div>
    <div class="post-region" v-if="postData.regionInfo?.province?.name">
      {{ postData.regionInfo?.province?.name }}
    </div>

    <!--  评论列表  -->
    <!--    TODO tokenId-->
    <comment-list v-if="postData.id" :postId="postData.id" type="post" />

    <div class="post-thumb">
      <Thumb
        :likeCount="postData.likeCount"
        :dislikeCount="postData.dislikeCount"
        :myAction="postData.myLike"
        @onClickThumb="onInvokeApp"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { Image as VanImage, List as VanList } from "vant";
import dayjs from "dayjs";
import { time } from "@frontend/echo-utils";
import NftApi from "@/apis";
import DownloadBanner from "@/components/DownloadBanner/index.vue";
import { getQuery } from "@/utils/url";
import { invokeAppByYYB } from "@/utils/mob";
import HoverGuideBtn from "@/components/HoverGuideBtn/index.vue";
import GridImgs from "@/components/GridImgs/index.vue";
import CommentList from "@/components/CommentList/index.vue";
import Thumb from "@/components/Thumb/index.vue";
import ArrowRight from "@/components/ArrowRight/index.vue";

const postData = ref({});

const onInvokeApp = () => {
  const { postId } = getQuery();
  invokeAppByYYB({
    targetPath: "community/post/detail",
    params: {
      id: postId,
    },
  });
};

const handleToSection = (section) => {
  invokeAppByYYB({
    targetPath: "community/post/label",
    params: {
      id: section.id,
    },
  });
};

const initPostData = async () => {
  const { postId } = getQuery();
  const { data } = await NftApi.getPostDetail({
    postId,
  });
  postData.value = data;
  postData.value.attachments = postData.value.attachments?.map((item) => {
    return item.url;
  });
  postData.value.createdAt = dayjs(+postData.value.createdAt).format(
    "YYYY-MM-DD"
  );
};

initPostData();
</script>

<style lang="scss">
.post {
  background-color: #fff;
  padding: 32rpx 30rpx 180rpx 30rpx;
  min-height: 100vh;
  &-header {
    display: flex;
    align-items: center;
    &-avatar {
      width: 76rpx;
      height: 76rpx;
      border-radius: 100%;
      overflow: hidden;
      border: 2rpx solid rgba(0, 0, 0, 0.04);
    }
    &-user {
      margin-left: 16rpx;
      &-name {
        color: #000;
        font-size: 28rpx;
        font-style: normal;
        font-weight: 500;
        line-height: 44rpx; /* 157.143% */
      }
      &-time {
        display: flex;
        align-items: center;
        margin-top: 2rpx;
        color: rgba(0, 0, 0, 0.5);
        font-size: 20rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 22rpx; /* 110% */
        &-section {
          margin-left: 4rpx;
          color: rgba(0, 0, 0, 0.7);
          font-size: 20rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 22rpx; /* 110% */
        }
      }
    }
  }
  &-content {
    margin-top: 34rpx;
    overflow: hidden;
    color: #000;
    font-size: 32rpx;
    font-style: normal;
    font-weight: 400;
    line-height: 56rpx; /* 175% */
  }
  &-imgs {
    margin-top: 24rpx;
  }
  &-region {
    margin-top: 24rpx;
    display: flex;
    align-items: center;
    align-self: stretch;
  }
  &-thumb {
    padding: 16rpx 30rpx 68rpx 30rpx;
    width: 750rpx;
    margin-top: 32rpx;
    position: fixed;
    left: 0;
    bottom: 0;
    background-color: #fff;
  }

  .section {
    margin-top: 48rpx;
    &-title {
      color: #000;
      font-size: 40rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 52rpx; /* 130% */
    }
  }
}
</style>
